import { useContext } from "@nuxtjs/composition-api";
import { useDataTableFilterOperators } from "../data-table/useDataTableFilterOperators";
import { useRatingActions } from "../ratings/useRatingActions";

export const REFUND_PAYMENT_METHODS = {
  BANK_TRANSFER: "BANK_TRANSFER",
  CREDIT_CARD: "CREDIT_CARD",
};

export function usePersonActions() {
  const { $mutate, $query } = useContext();
  const operators = useDataTableFilterOperators();

  const ratingsActions = useRatingActions();

  async function deletePersonById(id) {
    try {
      const DELETE_PERSON = await import("./deletePersonById.graphql");
      await $mutate(DELETE_PERSON, { id });
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function getPersonById(id) {
    try {
      const GET_PERSON = await import("./getPersonById.graphql");
      const { personById } = await $query(GET_PERSON, { id });
      return personById;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function getPersonPersonTenderInfo({
    personId,
    professionIds,
    startDate,
    endDate,
  }) {
    try {
      const GET_PERSON_TENDER_INFO = await import(
        "./getPersonTenderInfo.graphql"
      );
      const { personById } = await $query(GET_PERSON_TENDER_INFO, {
        personId,
        professionIds,
        startDate,
        endDate,
      });
      return personById;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function getPersons(params) {
    try {
      const GET_PERSONS = await import("./personsDataTable.graphql");
      const { personsDataTable } = await $query(GET_PERSONS, params);
      return personsDataTable;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function getPersonTenders({
    page,
    pageSize,
    startDate,
    endDate,
    professionIds,
    personId,
  }) {
    try {
      const GET_PERSON_TENDERS = await import("./getPersonTenders.graphql");

      const params = {
        page,
        pageSize,
        personId,
        order: "desc",
        orderBy: "created",
        filters: [
          {
            filterId: "receivedByPerson",
            operator: operators.EQUALS,
            value: personId,
          },
        ],
      };

      if (startDate && endDate) {
        params.filters.push({
          filterId: "created",
          operator: operators.BETWEEN,
          min: startDate,
          max: endDate,
        });
      }
      if (professionIds?.length) {
        params.filters.push({
          filterId: "professionId",
          operator: operators.IN,
          items: professionIds,
        });
      }

      const { tendersDataTable } = await $query(GET_PERSON_TENDERS, params);
      return tendersDataTable;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function getPersonRatings({ page, pageSize, personId }) {
    try {
      const params = {
        page,
        pageSize,
        order: "desc",
        orderBy: "created",
        filters: [
          {
            filterId: "mateId",
            operator: operators.EQUALS,
            value: personId,
          },
        ],
      };

      return await ratingsActions.getRatings(params);
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function requestRefundForPerson({
    professionalId,
    amount,
    refundPaymentMethod,
    accountNumber,
    accountHolder,
  }) {
    try {
      const REQUEST_REFUND = await import("./requestRefund.graphql");
      const { requestRefund } = await $mutate(REQUEST_REFUND, {
        input: {
          professionalId,
          amount,
          refundPaymentMethod,
          bankTransferDetails:
            refundPaymentMethod === REFUND_PAYMENT_METHODS.BANK_TRANSFER
              ? {
                  accountNumber,
                  accountHolder,
                }
              : undefined,
        },
      });
      return requestRefund;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  return {
    deletePersonById,
    getPersonById,
    getPersonPersonTenderInfo,
    getPersons,
    getPersonTenders,
    getPersonRatings,
    requestRefundForPerson,
  };
}
