import { useContext } from "@nuxtjs/composition-api";

export function useZoneActions() {
  const { $mutate, $query } = useContext();

  async function getZonePositionHistoriesDataTable(params) {
    try {
      const GET_ZONE_POSITIONS = await import(
        "./zonePositionHistoriesDataTable.graphql"
      );
      const { zonePositionHistoriesDataTable } = await $query(
        GET_ZONE_POSITIONS,
        params
      );
      return zonePositionHistoriesDataTable;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function shufflePeopleInZone(id) {
    try {
      const SHUFFLE_PEOPLE_IN_ZONE = await import(
        "./shufflePeopleInZone.graphql"
      );
      await $mutate(
        SHUFFLE_PEOPLE_IN_ZONE,
        { id },
        { showErrorToast: true, showSuccessToast: true }
      );
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function getSubcountyStatInfos(professionId) {
    try {
      const GET_SUBCOUNTY_STATISTICS = await import(
        "./getSubcountyStatistics.graphql"
      );
      const { subcounties } = await $query(GET_SUBCOUNTY_STATISTICS, {
        professionId,
      });
      return subcounties;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function saveZone(submitData) {
    try {
      if (submitData.id !== null) {
        const UPDATE_ZONE = await import("./updateZone.graphql");
        return await $mutate(UPDATE_ZONE, submitData);
      } else {
        const CREATE_ZONE = await import("./createZone.graphql");
        return await $mutate(CREATE_ZONE, submitData);
      }
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function deleteZoneById(id) {
    try {
      const DELETE_ZONE = await import("./deleteZoneById.graphql");
      await $mutate(DELETE_ZONE, { id }, { showErrorToast: true, showSuccessToast: true });
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function convertPeopleToZone(zoneId) {
    try {
      const CONVERT_PEOPLE_TO_ZONE = await import("./convertPeopleToZone.graphql");
      await $mutate(CONVERT_PEOPLE_TO_ZONE, { zoneId }, { showErrorToast: true, showSuccessToast: true });
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function getZoneById(id) {
    try {
      const GET_ZONE = await import("./getZoneById.graphql");
      const { zoneById } = await $query(GET_ZONE, { id });
      return zoneById;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function getZones(params) {
    try {
      const GET_ZONES = await import("./zonesDataTable.graphql");
      const { zonesDataTable } = await $query(GET_ZONES, params, {
        showErrorToast: true,
      });
      return zonesDataTable;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  return {
    getSubcountyStatInfos,
    saveZone,
    deleteZoneById,
    getZoneById,
    getZones,
    getZonePositionHistoriesDataTable,
    shufflePeopleInZone,
    convertPeopleToZone,
  };
}
